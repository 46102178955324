import React, {Component} from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "./signature.css";
import AIHD from "../../../Images/aihdfinal.png"

class App extends Component{
  render(){
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear"
    };
    return(
      <div className="mainContainer">
        <center><p className="mt-3" style={{fontSize:"30px"}}>Trusted by</p></center>
        <Slider {...settings}>
          <div className="container">
            <img src={AIHD} alt='img'/>
          </div>
          <div className="container">
            <img src="https://erpfe.innocule.tech/static/media/innocule.63dc573a397c63b6904d.webp" alt='img'/>
          </div>
          <div className="container">
          <img src={AIHD} alt='img'/>
          </div>
          <div className="container">
          <img src="https://erpfe.innocule.tech/static/media/innocule.63dc573a397c63b6904d.webp" alt='img'/>
          </div>
          <div className="container">
          <img src={AIHD} alt='img'/>
          </div>
          <div className="container">
          <img src="https://erpfe.innocule.tech/static/media/innocule.63dc573a397c63b6904d.webp" alt='img'/>
          </div>
        </Slider>
      </div>
    );
  }
}

export default App;