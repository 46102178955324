import React, { Component } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getDataFromList, getFieldValue, sortPositionWise, validateForm, validateRequireds } from '../../../lib/utils';
import { validator } from '../../../lib/Validation';
import { setNotification } from '../../common/action';
import { checkUnique, fetchActiveCustomForm, fetchData, saveFormData } from '../../forms/action';
import CustomFieldHandler from '../customfields/FieldHandler';
import CustomNavbar from '../common/CustomNavbar';
import image from "../../../Images/nodata.png";
import Embeded_Code_Modal from '../common/Embeded_Code_Modal';

class CustomedForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            list: [],
            colWidthList: {},
            my_placeholders: {},
            requireds: {},
            formDetails: {},
            data_obj: {},
            validationResponse: false,
            cardList: [],
            currentCardNo: null,
            currentCardDetails: {},
            data: [],
            show: false
        }
    }

    validateData_obj = () => {
        var data = { ...this.state.data_obj }
        var myList = Object.keys(data).length > 0 && Object.keys(data).map((item, index) => {
            if (parseInt(item) !== undefined) {
                return { 'value': data[item], 'field': parseInt(item) }
            }
        })
        return myList
    }

    setDataToState = (data) => {
        this.state.data_obj[data.field] = data.value
        this.setState({ data_obj: this.state.data_obj })
    }

    componentDidMount = () => {

        this.props.fetchActiveCustomForm(this.props.token.access, this.props.params.id)
        if (this.props.currentCustomFormDetails.custom_form_data) {
            // this.props.getData(this.props.token.access, this.props.currentCustomFormDetails.form_id);
            this.setState({ formDetails: JSON.parse(this.props.currentCustomFormDetails.custom_form_data) })
            this.setState({ cardList: (JSON.parse(this.props.currentCustomFormDetails.custom_form_data).cards && JSON.parse(this.props.currentCustomFormDetails.custom_form_data).cards.length > 0) ? sortPositionWise(JSON.parse(this.props.currentCustomFormDetails.custom_form_data).cards) : [] })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.data !== prevProps.data) {
            this.setState({ data: this.props.data.data });
        }

        if (prevProps.currentCustomFormDetails !== this.props.currentCustomFormDetails) {
            this.setState({ formDetails: JSON.parse(Object.keys(this.props.currentCustomFormDetails).length >0 && this.props.currentCustomFormDetails.custom_form_data) })
        }
        if (prevProps.currentCustomFormDetails.custom_form_data !== this.props.currentCustomFormDetails.custom_form_data) {
            this.setState({ cardList: (JSON.parse(this.props.currentCustomFormDetails.custom_form_data).cards && JSON.parse(this.props.currentCustomFormDetails.custom_form_data).cards.length > 0) ? sortPositionWise(JSON.parse(this.props.currentCustomFormDetails.custom_form_data).cards) : [] })
        }
    }

    onSubmitForm = () => {
        var myList = this.validateData_obj()
        if (myList) {
            var requiredData = validateRequireds(this.props.fields, myList)
            if (requiredData) {
                this.props.setNotification(true, `${requiredData} field is required.`, "danger")
            }
            else {
                if (validateForm(this.props.fields, myList)) {
                    var requestBody = {
                        'data': myList,
                        'template': this.state.formDetails.id
                    }
                    if (this.state.validationResponse === false) {
                        this.props.saveData(this.props.token.access, this.state.formDetails.id, requestBody);
                    }
                    else {
                        this.props.setNotification(true, 'Duplicate data not allowed', "danger")
                    }
                }
                else {
                    this.props.fields.length > 0 && this.props.fields.map((item) => {
                        this.props.setNotification(true, `${item.label.toUpperCase()} Field : ${this.validateForm(item.validation, getDataFromList(myList, item.id))}`, "danger")
                    })
                }
            }
        }
        else {
            this.props.setNotification(true, 'Please Fill The Input Fields', "danger")
        }
    }

    validateForm(validationObj, value) {
        if (validationObj === null) {
            return
        } else {
            if (!validator(validationObj, value) && value.length > 0) {
                return validationObj.error_message;
            }
        }
        return
    }



    render() {
        return (
            <div style={{ height: window.innerHeight, width: "100%", backgroundColor: "lightblue", overflowX: "hidden" }} >
                <CustomNavbar backURL={'/customforms'} shareFormUrl={`/forms/template/${this.state.formDetails.id}/share`} getEmbededCode={() => { this.setState({ show: true }) }} />

                <div style={{ width: "100%", marginBlock: "3%" }}>

                    {
                        Object.keys(this.state.formDetails).length == 0 ?
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img src={image} style={{ width: "25%", height: "25%", paddingTop: "16%" }} alt='img'></img>
                                    <br />
                                </div>
                                <div><center><p style={{ fontSize: "12px", marginLeft: "10px", color: "darkorange" }}>Opps ! You Don't Have Any Fields Yet</p></center>  </div>
                            </div>
                            :
                            this.state.formDetails.custom_template_type == 'S' ?
                                <center style={{ width: "65%", backgroundColor: "#fff", paddingBottom: 40, marginLeft: "17.5%" }}>
                                    <div style={{ backgroundImage: `url(${this.state.formDetails.image_header})`, height: "100%", width: "100%", backgroundSize: "cover", marginBottom: 30, paddingTop: 50, paddingBottom: 10 }}>
                                        <h3 style={{ marginInline: "5%", borderBottomStyle: "dashed", borderBottomWidth: 1, paddingBottom: "2%" }}>
                                            {this.state.formDetails.label && this.state.formDetails.label}</h3>
                                    </div>

                                    <Row style={{ display: "flex" }} className='custom-form'>
                                        {
                                            this.props.fields.length > 0 && this.props.fields.map((item, index) => {
                                                if (item.type !== 'OCR_VIEW') {
                                                    return (
                                                        <Col md={item.style == '' ? 12 : item.style} style={{ display: "flex", justifyContent: "center", marginBottom: 25 }}
                                                            key={index}>
                                                            <CustomFieldHandler
                                                                data={item}
                                                                type={item.type}
                                                                editable={false}
                                                                placeholder={''}
                                                                value={getFieldValue(item, this.validateData_obj() ?? [], false)}
                                                                onChange={(data) => {
                                                                    this.setDataToState(data);
                                                                }}
                                                                onBlur={(data) => {
                                                                    if (data !== '' && item.check_unique == true) {
                                                                        this.props.checkUnique(this.props.token.access, this.state.formDetails.id, item.id, data, (resp) => {
                                                                            if (resp.status === true) {
                                                                                this.props.setNotification(true, 'Already exists', "danger");
                                                                                this.setState({ validationResponse: true })
                                                                            }
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </Col>
                                                    )
                                                }
                                            })
                                        }
                                    </Row>
                                    <Row style={{ display: "flex", paddingInline: "10%", justifyContent: "center" }}>
                                        <Col md={2}>
                                            <Button style={{ width: "100%" }} variant="outline-success"
                                                onClick={() => this.onSubmitForm()} className='mt-4'>SUBMIT</Button>
                                        </Col>
                                    </Row>
                                </center>
                                :
                                <div style={{ width: "100%" }} >
                                    <center>
                                        <div style={{ width: "65%" }}>
                                            {this.state.currentCardNo == null &&
                                                <Card className='current-card' style={{ backgroundColor: "#fff", marginTop: "5%", borderRadius: 10, marginBottom: "3%" }}>
                                                    <div style={{ backgroundImage: `url(${this.state.formDetails.image_header})`, ...styles.form_details }} title='Form Details'>
                                                        <h4 style={{ marginInline: "5%", textAlign: "center", fontWeight: "bolder" }}>
                                                            {this.state.formDetails.label && this.state.formDetails.label.toUpperCase()}
                                                        </h4>
                                                        <p style={{ textAlign: "center" }}>{this.state.formDetails.description && this.state.formDetails.description}</p>
                                                        <p style={{ textAlign: "center" }}><b>{this.state.cardList.length}</b>  cards</p>
                                                    </div>
                                                    <div style={styles.next_btn}>
                                                        <div onClick={() => {
                                                            this.setState({ currentCardNo: 0, currentCardDetails: this.state.cardList[0] })
                                                        }} className='card-button' style={{ textAlign: "enter" }}> NEXT <i className="fa fa-long-arrow-right" aria-hidden="true" style={{ marginLeft: 7, fontSize: 17 }}></i></div>
                                                    </div>
                                                </Card>
                                            }

                                            {
                                                (Object.keys(this.state.currentCardDetails).length > 0 && this.state.currentCardNo !== null) &&
                                                <Card
                                                    className='current-card' style={{ backgroundColor: "#fff", borderRadius: 10, marginBottom: "3%", marginTop: "5%" }}>

                                                    <div style={{ backgroundImage: `url(${this.state.formDetails.image_header})`, ...styles.card_details }} title='Card Details'>
                                                        <div style={{ width: "85%" }}>
                                                            <h5 style={{ marginInline: "5%", textAlign: "center" }}>{this.state.currentCardDetails.card_name && this.state.currentCardDetails.card_name.toUpperCase()}</h5>
                                                            <p style={{ textAlign: "center" }}>{this.state.currentCardDetails.card_desc && this.state.currentCardDetails.card_desc}</p>
                                                        </div>
                                                    </div>
                                                    <Row style={{ display: "flex", paddingBlock: 30 }} className='custom-form'>
                                                        {
                                                            this.props.fields.length > 0 && this.props.fields.map((item, index) => {
                                                                if (item.type !== 'OCR_VIEW' && item.card_no == this.state.currentCardDetails.id) {
                                                                    return (
                                                                        <Col md={item.style == '' ? 12 : item.style} style={{ display: "flex", justifyContent: "center", marginBottom: 25 }}
                                                                            key={index}>
                                                                            <CustomFieldHandler
                                                                                data={item}
                                                                                type={item.type}
                                                                                editable={false}
                                                                                placeholder={''}
                                                                                value={getFieldValue(item, this.validateData_obj() ?? [], false)}
                                                                                onChange={(data) => {
                                                                                    this.setDataToState(data);
                                                                                }}
                                                                                onBlur={(data) => {
                                                                                    if (data !== '' && item.check_unique == true) {
                                                                                        this.props.checkUnique(this.props.token.access, this.state.formDetails.id, item.id, data, (resp) => {
                                                                                            if (resp.status === true) {
                                                                                                this.props.setNotification(true, 'Already exists', "danger");
                                                                                                this.setState({ validationResponse: true })
                                                                                            }
                                                                                        });
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </Row>
                                                    {
                                                        this.state.cardList.length - 1 == this.state.currentCardNo ?
                                                            <div style={styles.previous_btn}>
                                                                <div className='card-button' onClick={() => {
                                                                    if (this.state.currentCardNo == 0) { this.setState({ currentCardNo: null }) }
                                                                    else {
                                                                        this.setState({ currentCardDetails: this.state.cardList[this.state.currentCardNo - 1], currentCardNo: this.state.currentCardNo - 1, })
                                                                    }
                                                                }}><i className="fa fa-long-arrow-left" aria-hidden="true" style={{ marginRight: 7, fontSize: 17 }}></i> PREVIOUS</div>
                                                                <div style={{ width: 0.1, backgroundColor: "#fff" }}></div>
                                                                <div className='card-button' style={{ textAlign: "end" }}
                                                                    onClick={() => { this.onSubmitForm() }}> SUBMIT <i className="fa fa-long-arrow-right" aria-hidden="true" style={{ marginLeft: 7, fontSize: 17 }}></i>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div style={styles.previous_btn}>
                                                                <div className='card-button' onClick={() => {
                                                                    if (this.state.currentCardNo == 0) { this.setState({ currentCardNo: null }) }
                                                                    else {
                                                                        this.setState({ currentCardDetails: this.state.cardList[this.state.currentCardNo - 1], currentCardNo: this.state.currentCardNo - 1, })
                                                                    }
                                                                }}><i className="fa fa-long-arrow-left" aria-hidden="true" style={{ marginRight: 7, fontSize: 17 }}></i> PREVIOUS</div>
                                                                <div style={{ width: 0.1, backgroundColor: "#fff" }}></div>
                                                                <div className='card-button' style={{ textAlign: "end" }}
                                                                    onClick={() => {
                                                                        this.setState({ currentCardDetails: this.state.cardList[this.state.currentCardNo + 1], currentCardNo: this.state.currentCardNo + 1, })
                                                                    }}> NEXT <i className="fa fa-long-arrow-right" aria-hidden="true" style={{ marginLeft: 7, fontSize: 17 }}></i>
                                                                </div>
                                                            </div>
                                                    }

                                                </Card>
                                            }
                                        </div>
                                    </center>
                                </div>
                    }
                </div>

                {this.state.show &&
                    <Embeded_Code_Modal
                        show={this.state.show}
                        handleClose={() => this.setState({ show: false })}
                        currentCustomFormDetails={this.props.currentCustomFormDetails.custom_form_data && JSON.parse(this.props.currentCustomFormDetails.custom_form_data)}
                    />}

            </div>
        )
    }
}


const styles = {
    form_details: { paddingTop: "3%", paddingBottom: "2%", height: "100%", width: "100%", backgroundSize: "cover", fontFamily: "serif" },
    next_btn: { width: "100%", flexDirection: "row", display: "flex", borderEndStartRadius: 10, borderEndEndRadius: 10, backgroundColor: "rgb(5, 123, 138)", fontSize: 16, color: "#fff", justifyContent: "center", padding: 10, cursor: "pointer" },
    card_details: { paddingTop: "3%", height: "100%", width: "100%", backgroundSize: "cover", fontFamily: "serif", borderBottomStyle: "dashed", borderBottomWidth: 1, display: "flex", justifyContent: "space-evenly" },
    card_btn: { position: "relative", float: "right", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" },
    submit_btn: { width: "100%", flexDirection: "row", display: "flex", borderEndStartRadius: 10, borderEndEndRadius: 10, backgroundColor: "rgb(5, 123, 138)", fontSize: 16, color: "#fff", justifyContent: "center", padding: 10, paddingInline: 20, cursor: "pointer" },
    previous_btn: { width: "100%", flexDirection: "row", display: "flex", borderEndStartRadius: 10, borderEndEndRadius: 10, backgroundColor: "rgb(5, 123, 138)", fontSize: 16, color: "#fff", justifyContent: "space-between", padding: 10, paddingInline: 20, cursor: "pointer" },
    drag_field: { display: "flex", alignItems: "center", justifyContent: "center", borderStyle: "dashed", borderRadius: 15, marginInline: "5%", paddingBlock: 30 }
}

const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        fields: state.formsReducer.fields,
        currentCustomFormDetails: state.formsReducer.currentCustomFormDetails,
        data: state.formsReducer.data,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveData: (accessToken, templateId, requestBody) => dispatch(saveFormData(accessToken, templateId, requestBody)),
        checkUnique: (accessToken, templateId, fieldId, value, returnedData) => dispatch(checkUnique(accessToken, templateId, fieldId, value, returnedData)),
        setNotification: (show, text, color) => dispatch(setNotification(show, text, color)),
        getData: (accessToken, templateId) => dispatch(fetchData(accessToken, templateId)),
        fetchActiveCustomForm: (accessToken, form_id) => dispatch(fetchActiveCustomForm(accessToken, form_id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomedForm);