import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row, Card, Button, Stack } from 'react-bootstrap';
import DataTable from '../../common/components/DataTable';
import { fetchProjectMembers, updateProjectMember, fetchProjectDetails, deleteProjectMember } from '../action';
import AddMemberModal from './AddMemberModal';
import userImage from '../../../Images/profile2.jpg';
import DeleteModal from '../../common/components/DeleteModal';

class ProjectMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddMemberModal: false,
            showDeleteModal: false,
            projectId: this.props.params.id,
            label: '',
            description: '',
            show: false,
            search: '', options: ['OWNER', 'MAINTAINER', 'REPORTER']
        }
    }

    componentDidMount() {
        this.props.getProjectData(this.props.token.access, this.props.params.id);
        this.props.getProjectMembers(this.props.token.access, this.props.params.id);
    }

    handleUpdateAction(requestBody) {
        this.props.updateProjectMember(this.props.token.access, requestBody);
    }

    deleteAction(event) {
        this.props.removeProjectMember(this.props.token.access, event.id, this.props.params.id);
    }

    render() {
        return (
            <div className="main-content-container p-4 container-fluid">
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Row className='d-flex justify-content-end'>
                                    <Col>
                                        <h5>Project Members</h5>
                                        <h6 style={{ fontSize: 11 }}>Project: {this.props.projectDetails.name}</h6>
                                        <p>{this.props.projectDetails.description}</p>
                                    </Col>
                                    <Col md='2' >
                                        <Button onClick={() => this.setState({ showAddMemberModal: true })} className='full-width' size='sm'>Add Member
                                            <i className="fa fa-user-plus ms-4" aria-hidden="true"></i>

                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className='font-medium'>
                                <Row>
                                    <Col md='10'>
                                        <Form.Control size="sm" type="text" placeholder="Seach Members" required value={this.state.search}
                                            onChange={(event) => { this.setState({ search: event.target.value }); }} />
                                    </Col>
                                    <Col md='2' >
                                        <Button className='full-width' variant="success" onClick={() => this.handleSearchAction()} size='sm'>Search</Button>
                                    </Col>
                                </Row>
                                <div className='mt-4 font-medium'>
                                    <DataTable
                                        totalItemsCount={this.props.projectMembers.length}
                                        headers={[`Member Name`, `Email`, `Role`, `Action`]}
                                        onPageChange={(pageNumber) => this.props.getProjectMembers(this.props.token.access, this.props.params.id, pageNumber)}
                                        body={this.props.projectMembers.map((row, index) => {
                                            return [
                                                <div className='d-flex flex-row align-items-center'>
                                                    <img src={userImage} className='rounded-image' alt='user img'/>
                                                    <p className='ms-3'>{row.user.name}</p>
                                                </div>,
                                                row.user.email,
                                                <Form.Select aria-label="Default select example" defaultValue={row.level}
                                                    onChange={(e) => this.handleUpdateAction({ "ID": row.id, "user": row.user.id, "level": e.target.value, "project": this.props.params.id })}
                                                >
                                                    {this.state.options.map((row, index) => {
                                                        return (
                                                            <option value={row} key={index}>{row}</option>
                                                        )
                                                    })}
                                                </Form.Select>,
                                                <Button onClick={() => this.setState({ showDeleteModal: true, selectedData: row })} disabled={index === 0 ? true : false} variant="outline-danger" size='sm' className="m-1">REMOVE MEMBER</Button>
                                            ]
                                        })} />
                                </div>
                            </Card.Body>
                        </Card>
                        <DeleteModal modalText={"Remove Member ?"} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false })}
                            deleteAction={(status) => { if (status !== undefined && status === true) { this.deleteAction(this.state.selectedData) } }} selectedData={this.state.selectedData}
                        />
                        <AddMemberModal showModal={this.state.showAddMemberModal} handleClose={() => this.setState({ showAddMemberModal: false })} projectId={this.state.projectId} />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        projectMembers: state.projectReducer.projectMembers,
        projectDetails: state.projectReducer.projectDetails,
        token: state.usersReducer.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeProjectMember: (accessToken, memberId, projectId) => dispatch(deleteProjectMember(accessToken, memberId, projectId)),
        getProjectMembers: (accessToken, org_id, searchQuery, pageNumber) => dispatch(fetchProjectMembers(accessToken, org_id, searchQuery, pageNumber)),
        updateProjectMember: (accessToken, requestBody) => dispatch(updateProjectMember(accessToken, requestBody)),
        getProjectData: (accessToken, Id) => dispatch(fetchProjectDetails(accessToken, Id)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMembers);