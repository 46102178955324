import React, { Component } from "react";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentYear: new Date().getFullYear()
        };
    }

    render() {
        const { currentYear } = this.state;
        return (
            <footer className="site-footer">
                <div className="footer-inner font-small primary">
                    <div className="d-flex justify-content-center">
                        <div className="font-small">
                            Copyright &copy; {currentYear} &nbsp;
                            <a className="text-decoration-none primary font-weight-bold" href="https://epsumlabs.com">
                                Epsum Labs Private Limited.
                            </a>
                            &nbsp; All rights reserved.
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
