import React, { Component } from 'react'
import Navbar from "./Navbr";
// import Footer from "./lfooter";
import { Button, Card } from 'react-bootstrap';
import Privacy from '../../../Images/privacy.png'

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div>
        <Navbar/>
        <div class="row" >
                <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 ">
                <div className="sec-title text-center" style={{margin:"30px 0"}}>
              <h2>Privacy Policy</h2></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="container" >
        
                   
                  
                    <p>Greetings from Epsum Labs! We are a progressive technology company that
                        specialises in offering tailored solutions to businesses of all kinds. With our
                        expertise in embedded systems, IIOT, software development, and industrial
                        automation, we can provide creative solutions that are suited to your
                        requirements. This Privacy Policy outlines how we collect, use, disclose, and
                        protect your information when you visit our website or use our services.
                        
                        </p>
                        <h3  >Information we collect and use</h3>
                        <div class="container" style={{padding: "10px 0px 0 40px"}}>
        
                       
                        <ul>
                            <li style={{listStyle: "disc"}}>
                                <span style={{fontSize:"18px",fontWeight:600}}>Personal Data :</span>
                                <span>We may collect personal data, including your name, email
address, phone number, and company name, from you when you contact us or
use our services.

    </span>
                            </li>
                            <li style={{listStyle: "disc"}}>
                                <span style={{fontSize:"18px",fontWeight:600}}>Technical Data :</span>
                                <span > In order to enhance our website and services, we gather
technical data such as IP addresses, browser types, operating systems, and
referring URLs.
        </span>
                            </li>
                            <li style={{listStyle: "disc"}}>
                                <span style={{fontSize:"18px",fontWeight:600}}>Usage Data :</span>
                                <span > We gather information about your usage of our website and
services, including the pages you visit, the amount of time you spend there, and
the links you click.
        </span>
                            </li>
                           
                           
                        </ul>
                    </div>
                    <h3  style={{marginTop: "10px;"}}>How we use your information</h3>
                    <div class="container" style={{padding: "10px 0px 0 40px"}}>
    
                   
                    <ul>
                        <li style={{listStyle: "disc"}}>
                            <span style={{fontSize:"18px",fontWeight:600}}>Service delivery :</span>
                            <span >We utilise the information you provide to deliver and enhance
our services, which includes creating solutions tailored to meet your company's
needs. This might include getting in touch with you, handling financial
transactions, and providing the goods or services you've requested
</span>
                        </li>
                        <li style={{listStyle: "disc"}}>
                            <span style={{fontSize:"18px",fontWeight:600}}>Communication :</span>
                            <span> We might send you marketing materials, emails, updates, and
other things that we think you would find useful via your contact information.
You can opt out of these communications at any time by following the
instructions provided in the communication or contacting us directly.

    </span>
                        </li>
                        <li style={{listStyle: "disc"}}>
                            <span style={{fontSize:"18px",fontWeight:600}}>Legal compliance :</span>
                            <span> We may use your information to safeguard our rights and
the rights of our users, enforce our terms of service, and comply with legal
requirements. This might involve looking into policy violations, preventing
fraud, or attending to court orders.
    </span>
                        </li>
                        <li style={{listStyle: "disc"}}>
                            <span style={{fontSize:"18px",fontWeight:600}}>Business Operations :</span>
                            <span > We might use your information internally for things like
data analysis, accounting, auditing, and other administrative tasks that are
essential to running our company efficiently.

    </span>
                        </li>
                       
                    </ul>
                </div>

                <h3  style={{marginTop: "10px"}}>Your rights</h3>
                <div class="container" style={{padding: "10px 0px 0 40px"}}>
        
                       
                    <ul>
                        <li style={{listStyle: "disc"}}>
                            
                            <span>Request access to the personal information we hold about you.

</span>
                        </li>
                        <li style={{listStyle: "disc"}}>
                            
                            <span > Request correction of any inaccurate or incomplete information.
    </span>
                        </li>
                        <li style={{listStyle: "disc"}}>
                            
                            <span > Subject to specific legal requirements, request the deletion of your
personal information.
    </span>
                        </li>
                       
                       
                    </ul>
                </div>
                <h3  style={{marginTop: "10px"}}>Data retention</h3>
                <p>
                    Unless a longer retention period is required or allowed by law, we keep personal
information for as long as it takes to accomplish the goals specified in this
privacy policy. The type of information and the reason it was collected will
determine the duration of retention.

                </p>
                <p>
                    This Privacy Policy may be updated from time to time to reflect modifications
to our operations or changes authorised by law. We will notify you of any
significant changes by posting the new privacy policy on our website and
updating the effective date.

                </p>
                <p>If you have any queries regarding our cookies policy or privacy policies, please
                    contact us at:</p>
                    <p>Phone: (+91)7377073774</p>
                    <p>Email: support@epsumlabs.com</p>
                    </div>
                </div>
                </div>
        {/* <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                    <Card className='p-4 m-3' style={{ width: "70%" }}>
                        
                        <div style={{ overflowY: "scroll", height: "700px" }} id="jeev">

                            <center><h5>PrivacyPolicy</h5>
                                <hr style={{ width: "50%" }}></hr></center>
                            <center><img src={Privacy} style={{ height: "400px" }} alt='img'></img></center>
                            <p className='text-justify'><b>Part I - Information Gigaforms collects and controls:</b><br/>
We only collect the information that we actually need. Some of that is information that you actively give us when you sign up for an account. We store your name and contact information, but we don't store credit card information.</p>
                            <p><b>Part II-Information that We processes on your behalf:</b>
                                <br />
                                
If you handle your Custumer's data using Gigaforms, such as information about your customers or employees, you are entrusting that data to us for processing.</p>
                            <p>
                               

                            We hold the data in your account as long as you choose to use our Services. After you terminate your account, your data will be automatically deleted from our active database within 6 months and from our backups within 3 months after that.</p>
                           
                            <center><p>For questions, please e-mail<span style={{ paddingLeft: "10px", color: "red" }}><a href="mailto:upport@gigaforms.com">support@gigaforms.com
                            </a></span> </p></center>
                        </div>

                    </Card>
                </div> */}

        {/* <Footer/> */}
      </div>
    )
  }
}
