import React, { Component } from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

const data = [
  { name: '', value: "" },
  { name: 'Unused', value: 50 },
];

const COLORS = ['green', 'red'];

class PieChartExample extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       
    }
  }
 

  render() {
    return (
      <div style={{marginTop:"-70px",marginLeft:"50px"}}> 
        <div>
        <PieChart width={400} height={400}>
          <Pie
            data={this.props.data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label
          >
            {this.props.data && this.props.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
        </div>
      </div>
    );
  }
}

export default PieChartExample;
