import axios from "axios";
import React, { Component } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { base64ToUrl, redirect } from "../../../lib/utils";
import Toaster from "../../common/components/Toaster";
import {
  createCompanyProfile,
  fetchBillingUser,
  PlanDetails,
  UpgradePlan,
} from "../action";
var CryptoJS = require("crypto-js");

// var w

class BillingRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      GST: "",
      free: false,
      designation: "",
      contactperson: "",
      address: "",
      modalVisible: false,
      decodedString: "",
      country: "",
      loading: true,
      monthly: true,
      yearly: false,
      duration: null,
      subscription_type: "",
    };
  }
  handleChange = (event) => {
    const { value } = event.target;

    if (/^\d*$/.test(value)) {
      this.setState({ phone: value });
    }
  };

  componentDidMount() {
    this.props.billingUser(this.props.token.access);
    // const { latitude, longitude } = this.props;
    if (localStorage.getItem("latitude") !== null) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${localStorage
            .getItem("latitude")
            .toString()},${localStorage
            .getItem("longitude")
            .toString()}&key=AIzaSyDjCX0hCACCUVwfOsI5uWVouJv7rJtGgn0
      `
        )
        .then((response) => {
          const addressComponents = response.data.results[0].address_components;
          const countryComponent = addressComponents.find((component) =>
            component.types.includes("country")
          );
          const country = countryComponent.long_name;
          this.setState({ country, loading: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
        });
    }
    this.props.PlanDetails();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.billinguser !== this.props.billinguser) {
      this.setState({
        name:
          this.props.billinguser.user &&
          this.props.billinguser.user.company_name,
        phone: this.props.billinguser.user && this.props.billinguser.user.phone,
        email: this.props.billinguser.user && this.props.billinguser.user.email,
      });
    }
  }

  onCreateOrder = (type, duration) => {
    console.log("STATE", this.state);
    if (this.props.billinguser.id == undefined) {
      let requestBody = {
        billing_name: this.state.name,
        contact_no: this.state.phone,
        email: this.state.email,
        gst_no: this.state.GST,
        address: this.state.address,
        contact_person: this.state.contactperson,
        designation: this.state.designation,
        subscription_type: type,
        duration: duration,
      };
      this.props.createCompany(this.props.token.access, requestBody);
    } else {
      let requestBody2 = {
        subscription_type: type,
        duration: duration,
        billing: this.props.billinguser.id,
      };
      this.props.UpgradePlan(this.props.token.access, requestBody2);
    }

    this.setState({
      name: "",
      email: "",
      phone: "",
      GST: "",
    });
  };

  render() {
    return (
      <>
        <div>
          <Modal
            show={this.state.modalVisible}
            onHide={this.handleCloseModal}
            backdrop="static"
            keyboard={false}
            centered={true}
            size={"lg"}
          >
            <Modal.Body>
              <iframe
                src={this.state.decodedString}
                title="description"
                style={{ height: window.innerHeight / 1.3, width: "100%" }}
              ></iframe>
            </Modal.Body>
          </Modal>
        </div>
        <div className="main-content-container p-4 container-fluid">
          <Row style={{ position: "absolute", right: 0, top: 0 }}>
            <Col>
              <Toaster />
            </Col>
          </Row>
          {this.props.billinguser.active !== "YES" && <p>Comapny Profile</p>}

          <Card className="p-4">
            <Card.Body className="font-medium">
              {this.props.billinguser.active !== "YES" && (
                <div>
                  <Row>
                    <Col>
                      <Form.Label>
                        Comapny Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Full Name"
                        required
                        defaultValue={this.state.name}
                        onChange={(event) => {
                          this.setState({ name: event.target.value });
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>
                        Comapny Email <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        required
                        defaultValue={this.state.email}
                        onChange={(event) => {
                          this.setState({ email: event.target.value });
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>
                        Comapny Phone <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your Number "
                        required
                        defaultValue={this.state.phone}
                        onChange={this.handleChange}
                        maxLength={"10"}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Label>Contact Person</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Contact Person"
                        value={this.state.contactperson}
                        onChange={(event) => {
                          this.setState({ contactperson: event.target.value });
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Designation</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Designation"
                        value={this.state.designation}
                        onChange={(event) => {
                          this.setState({ designation: event.target.value });
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>GST NO</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your GST "
                        value={this.state.GST}
                        onChange={(event) => {
                          this.setState({ GST: event.target.value });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <br />
                    <Col className="col-md-12">
                      <label
                        for="exampleFormControlTextarea1"
                        class="form-label"
                      >
                        Address
                      </label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        value={this.state.address}
                        onChange={(event) => {
                          this.setState({ address: event.target.value });
                        }}
                      ></textarea>
                    </Col>
                  </Row>
                  <p className="m-2">
                    <span style={{ color: "red", fontSize: "15px" }}>
                      <b>Note:</b>
                    </span>{" "}
                    Please provide all the information and click on the plan
                    your want to choose .
                  </p>
                  <br />
                </div>
              )}

              <section className="pricing-section" style={{ padding: "0" }}>
                <div className="container">
                  <div className="sec-title text-center">
                    <span className="title">Get plan</span>
                  </div>
                  <div>
                    <center>
                      <div
                        style={{
                          border: "0.5px solid grey",
                          width: "19%",
                          height: "50px",
                          borderRadius: "20px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginTop: "-3rem",
                        }}
                      >
                        <Button
                          variant={this.state.monthly ? "success" : "light"}
                          size="lg"
                          className="m-1"
                          style={{
                            border: "none",
                            fontSize: "17px",
                            borderRadius: "20px",
                          }}
                          onClick={() => {
                            this.setState({ monthly: true, yearly: false });
                          }}
                        >
                          Monthly
                        </Button>
                        <Button
                          variant={this.state.yearly ? "success" : "light"}
                          size="lg"
                          className="m-1"
                          style={{
                            border: "none",
                            fontSize: "17px",
                            borderRadius: "20px",
                          }}
                          onClick={() => {
                            this.setState({ yearly: true, monthly: false });
                          }}
                        >
                          Yearly
                        </Button>
                      </div>
                    </center>
                  </div>
                  <br />
                  <div className="outer-box">
                    {this.props.plandetails &&
                      this.props.plandetails.results && (
                        <div className="row">
                          {this.props.plandetails.results.map((plan, index) => (
                            <div
                              key={index}
                              className={
                                this.props.billinguser.active !== ""
                                  ? "pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                                  : "pricing-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
                              }
                              data-wow-delay={`${index * 400}ms`}
                            >
                              <div className="inner-box">
                                {/* <div className="icon-box">
                    <div className="icon-outer">
                      <i className={`fa ${index === 0 ? 'fa-paper-plane' : index === 1 ? 'fa-bandcamp' : index === 2 ? 'fa-diamond' : 'fa-anchor'}`}></i>
                    </div>
                  </div> */}
                                <div className="price-box">
                                  <div className="title">{plan.name}</div>
                                  <h4 className="price">
                                    ₹
                                    {this.state.monthly
                                      ? plan.monthly_amount === 9999999
                                        ? "--"
                                        : plan.monthly_amount
                                      : plan.yearly_amount === 9999999
                                      ? "--"
                                      : plan.yearly_amount}
                                  </h4>
                                </div>
                                <ul className="features">
                                  <li className="true">
                                    Forms-
                                    <b>
                                      {plan.form_count === 9999999
                                        ? "--"
                                        : plan.form_count}
                                    </b>
                                  </li>
                                  <li className="true">
                                    Teams-
                                    <b>
                                      {plan.team_count === 9999999
                                        ? "--"
                                        : plan.team_count}
                                    </b>
                                  </li>
                                  <li className="false">
                                    Workflow-
                                    <b>
                                      {plan.workflow_count === 9999999
                                        ? "--"
                                        : plan.workflow_count}
                                    </b>
                                  </li>
                                  <li className="false">
                                    Space-
                                    <b>
                                      {plan.space_assign === "9999999"
                                        ? "--"
                                        : plan.space_assign}
                                      MB
                                    </b>
                                  </li>
                                  <li className="false">
                                    Email-
                                    <b>
                                      {plan.email_count === 9999999
                                        ? "--"
                                        : plan.email_count}
                                    </b>
                                  </li>
                                  <li className="false">
                                    Custom Form-
                                    <b>
                                      {plan.custome_form_count === 9999999
                                        ? "--"
                                        : plan.custome_form_count}
                                    </b>
                                  </li>
                                  <li className="false">
                                    E-signature-
                                    <b>
                                      {plan.e_signature_count === 9999999
                                        ? "--"
                                        : plan.e_signature_count}
                                    </b>
                                  </li>
                                  <li className="false">
                                    Submission-
                                    <b>
                                      {plan.monthly_submission === 9999999
                                        ? "--"
                                        : plan.monthly_submission}
                                    </b>
                                  </li>
                                  <li className="false">
                                    User-
                                    <b>
                                      {plan.field_count === 9999999
                                        ? "--"
                                        : plan.field_count}
                                    </b>
                                  </li>
                                  <li className="false">
                                    Form Field Type-
                                    <b>
                                      {plan.user_count === 9999999
                                        ? "--"
                                        : plan.user_count}
                                    </b>
                                  </li>
                                  <li className="false">
                                    Form Sharing-
                                    <b>
                                      {plan.form_sharing === 9999999
                                        ? "--"
                                        : plan.form_sharing}
                                    </b>
                                  </li>
                                  <li className="false">
                                    Form Template-
                                    <b>
                                      {plan.form_template === 9999999
                                        ? "--"
                                        : plan.form_template}
                                    </b>
                                  </li>
                                  <li className="false">
                                    Mobile App-
                                    <b>
                                      {plan.form_notification === 9999999
                                        ? "--"
                                        : plan.form_notification}
                                    </b>
                                  </li>
                                </ul>
                                <div className="btn-box">
                                  <a
                                    className="theme-btn"
                                    style={{ textDecoration: "none" }}
                                    onClick={() => {
                                      this.onCreateOrder(
                                        plan.name,
                                        this.state.monthly ? 30 : 365
                                      );
                                    }}
                                  >
                                    BUY plan
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                </div>
              </section>
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.usersReducer.token,
    billinguser: state.billingReducer.billinguser,
    plandetails: state.billingReducer.plandetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCompany: (acessToken, requestbody) =>
      dispatch(createCompanyProfile(acessToken, requestbody)),
    billingUser: (accessToken) => dispatch(fetchBillingUser(accessToken)),
    UpgradePlan: (acessToken, requestBody2) =>
      dispatch(UpgradePlan(acessToken, requestBody2)),
    PlanDetails: () => dispatch(PlanDetails()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingRegister);
