import React, { Component } from 'react'
import Footer from "./lfooter";
import Navbar from "./Navbr";
import data from "../../../Images/Data-Collection.jpg";
import challanges from "../../../Images/challanges.webp";
import LT from "../../../Images/LT.png";
import benifits from "../../../Images/benefits.png";

export default class casestudy extends Component {
  render() {
    return (
      <div>
        <Navbar/>
        <section>
        <div className="sec-title text-center">
                            <span className="title">Streamlining Data Collection Efforts through GigaForms</span>
                        </div>
                       
                    <div className="container" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='row'>
                    <div className='col-md-6' data-aos="zoom-in-right">
                        <div >
                            <p style={{fontWeight:"600"}}>Seeking a more efficient and accessible solution, a corporation turned to GigaForms to enhance their data collection efforts. </p>
                            <p className='text-right' style={{display:"flex",justifyContent:"center",alignItems:"center"}}>This part to show on the landing page when someone hovers the cursor over the case study. Once someone clicks, the below case study should be showing.</p>
                        </div>
                    </div>
                    <div className='col-md-6' data-aos="zoom-in-left">
                        <img src={data}style={{height:"250px",width:"800px"}} alt='img'></img>
                        
                    </div>
                    </div>
                
                    
                    </div>

                    <div className="container" style={{marginTop:"20px"}}>
                      <p style={{textAlign:'justify'}}>A leading provider of innovative solutions, faced significant challenges in creating online forms for various purposes. The existing process was time-consuming, requiring coding expertise and extensive resources. Seeking a more efficient and accessible solution, they turned to GigaForms to enhance their data collection efforts.</p>
                    </div>
                    
                    <div className="container" style={{marginTop:"30px"}}>
                    <p style={{fontWeight:"1000",textAlign:"center"}}>Challenges:</p>
                   
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='row' >
                    <div className='col-md-6' data-aos="zoom-in-right">
                        <div style={{marginTop:"50px"}}>
                        <ol className='d'>
                                <li style={{textAlign:'justify'}}> Simplifying the form creation process without the need for coding knowledge.</li>
                                <li style={{textAlign:'justify'}}>Create a variety of online forms, ranging from simple to complex registration forms.</li>
                                <li style={{textAlign:'justify'}}>Expedite data collection procedures to improve efficiency.</li>
                                <li style={{textAlign:'justify'}}>Improve the user experience for form respondents.</li>
                                <li style={{textAlign:'justify'}}>Streamline data analysis and reporting.</li>
                            </ol>
                            
                            
                        </div>
                    </div>
                    <div className='col-md-6 d-flex justify-content-center' data-aos="zoom-in-left">
                        <img src={challanges} alt='img'></img>
                        
                    </div>
                    </div>
                
                    
                    </div>
                    </div>

                    <div className="container" style={{marginTop:"30px"}}>
                    <p style={{fontWeight:"1000",textAlign:"center"}}>Solution:</p>
                    
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='row'>
                <div className='col-md-4' data-aos="zoom-in-left">
                        <img src={LT} alt='img'></img>
                        
                    </div>
                    <div className='col-md-8' data-aos="zoom-in-right">
                        <div >
                            
                            <p className='text-right' style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"120px",textAlign:"justify"}}>The corporation integrated GigaForms into their existing workflow, allowing their teams to create online forms seamlessly. GigaForms offered a range of features, including drag-and-drop functionality, customizable templates, and real-time preview, enabling the firm to design forms according to their specific requirements. The forms were created in minutes, eliminating the need for manual coding.

</p>
                        </div>
                    </div>
                
                    </div>
                
                    
                    </div>

                    </div>

                    <div className="container" style={{marginTop:"30px"}}>
                    <p style={{fontWeight:"1000",textAlign:"center"}}>Results:</p>
                   
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='row'>
                    <div className='col-md-6' data-aos="zoom-in-right">
                        <div >
                        <ol className='d'>
                                <li style={{textAlign:'justify'}}><b>Ease of Form Creation:</b> GigaForms proved to be an intuitive and user-friendly tool, eliminating the requirement for coding knowledge. The drag-and-drop functionality enabled the teams to build forms quickly and efficiently. The customizable templates provided flexibility, allowing for consistent branding across all forms.</li>
                                <li style={{textAlign:'justify'}}><b>Increased Efficiency:</b> The implementation of GigaForms significantly reduced the time required to create online forms. The streamlined process eliminated manual coding, enabling the team to focus on other core tasks. The rapid form creation capabilities also led to faster data collection, improving overall operational efficiency.</li>
                                <li style={{textAlign:'justify'}}><b>Enhanced User Experience:</b> GigaForms allowed them to create user-friendly forms, resulting in an improved experience for respondents. The tool's intuitive design and responsive layout ensured compatibility across various devices, enabling seamless form completion. This led to higher response rates and increased user satisfaction.</li>
                                <li style={{textAlign:'justify'}}><b>Simplified Data Analysis and Reporting:</b> GigaForms provided the team with robust data analysis capabilities. The tool facilitated the collection and aggregation of form responses in a centralized dashboard. The team could easily generate comprehensive reports and analyze the collected data in real-time. This streamlined the decision-making process and facilitated data-driven insights.</li>
                            </ol>
                            
                           
                        </div>
                    </div>
                    <div className='col-md-6 d-flex justify-content-center' data-aos="zoom-in-left">
                        <img src={benifits} alt='img'></img>
                        
                    </div>
                    </div>
                
                    
                    </div>
                    </div>
                    <div className="container" style={{marginTop:"10px"}}>
                      <p><b>Conclusion:</b></p>
                      <p style={{textAlign:"justify"}}>By adopting GigaForms, the organization successfully streamlined their data collection efforts. The tool's intuitive interface, drag-and-drop functionality, and customizable templates empowered them to create a wide range of online forms efficiently. The simplified process not only saved time and resources but also improved the user experience for form respondents. With GigaForms' comprehensive data analysis and reporting features, they gained valuable insights to drive informed decision-making. Overall, GigaForms proved to be an indispensable tool in revolutionizing the corporation's data collection procedures.</p>
                    </div>
        </section>
        <Footer />
      </div>
    )
  }
}
