import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { connect } from 'react-redux';
import { verifyEmail } from '../action';

class VerifyUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            token: ""
        }
    }
 

    componentDidMount() {
        var location = window.location.href;
        var paramString = location.split('?')[1];
        var queryString = new URLSearchParams(paramString);
        for (let pair of queryString.entries()) {
            if (pair[0] === "token") {
                this.setState({ token: pair[1] })
            }
            else {

            }


        };
        setTimeout(() => {
            this.props.verifyEmail(this.state.token)
            
        }, 1000);
    }

    render() {
        return (
            <div style={{ overflowX: "hidden" }}>
                <center><Card style={{ width: '30rem', marginTop: "200px", }}>
                    <Card.Img variant="top" src="https://gigaforms.epsumlabs.com/static/media/final.59f442c9898465a3d2d9.png" alt='img'/>
                    <Card.Body>
                        <Card.Title>Email Verified</Card.Title>
                        <Card.Text>
                            Your email was Sucessfully Verified 
                        </Card.Text>
                       <a href='/login'> <Button variant="primary">Go to Login</Button></a>
                    </Card.Body>
                </Card>
                </center>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {
        verifyEmail: (token) => dispatch(verifyEmail(token))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);

