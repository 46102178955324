import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import routes from "./routes";
import { BaseLayout } from "../layouts";


export default function App(props) {
    const navigation = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Check if a canonical link tag exists, if not, create one
        let canonicalLink = document.querySelector("link[rel='canonical']");
        
        if (!canonicalLink) {
          canonicalLink = document.createElement('link');
          canonicalLink.rel = 'canonical';
          document.head.appendChild(canonicalLink);
        }
    
        // Update the href of the canonical link tag
        canonicalLink.href = `${window.location.origin}${location.pathname}`;
    
      }, [location.pathname]); // Run the effect when the pathname changes
    return (
        <Routes>
            {routes.map((route, index) => {
                return (
                    <Route
                        index={true}
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        element={route.layoutType === null ?
                            <route.component navigate={navigation} location={location} /> :
                            <BaseLayout {...props}>
                                <route.component navigate={navigation} location={location} />
                            </BaseLayout>
                        }
                    />
                );
            })}
            <Route path="*" element={<p>Not Found</p>} />
        </Routes>
    );
}
