import React, { Component } from 'react'
import Navbar from "./Navbr";
import Footer from "./lfooter";
import features_dmsintegration from "../../../Images/features-dms-integration.jpg"
import features_dragdrop from "../../../Images/features-drag-drop.jpg"
import features_cusomization from "../../../Images/features-cusomization.jpg"
import features_conditional from "../../../Images/features-conditional-logic.jpg"
import Multi_StepForms from "../../../Images/Multi-StepForms.jpg"
import Real_TimeDataAnalytics from "../../../Images/Real-TimeDataAnalytics.jpg"
import AdvancedFormFieldOptions from "../../../Images/AdvancedFormFieldOptions.jpg"
import AutomatedEmailNotifications from "../../../Images/AutomatedEmailNotifications.jpg"
import ResponsiveMobile from "../../../Images/ResponsiveMobile-FriendlyDesign.jpg"


import FormEmbedding_and_Sharing from "../../../Images/Form Embedding and Sharing.png"
import SecureDataHandling from "../../../Images/Secure Data Handling.png"
import FileUploadCapability from "../../../Images/File Upload Capability.png"
import CustomizableThemes from "../../../Images/Customizable Themes and Branding.png"
import APIsforThirdpartyintegration from "../../../Images/APIs for Third party integration.png"
import UserAccessControl from "../../../Images/User Access Control.png"
import FormAnalyticsDashboard from "../../../Images/Form Analytics Dashboard.png"
import DataExportOptions from "../../../Images/Data Export Options.png"
export default class Feature extends Component {
    render() {
        return (
            <div>
                <Navbar />

                
                <section id="feature" className="services sections-bg" style={{ backgroundColor: "white" }}>
                    <div className='container p-6'>
                        <div className="sec-title text-center">
                            <h2>Features</h2></div>
                        <div className="row gy-4" data-aos-delay="100">

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={features_dragdrop} alt='img'/>
                                    </div>
                                    <h3 className="text" > Drag-and-Drop Form Builder</h3>
                                    <p className="text">Easily create and customize forms using a drag-and-drop interface, with no coding required.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={features_cusomization} alt='img'/>
                                    </div>
                                    <h3 className="text" > Customizable Templates</h3>
                                    <p className="text">Access a variety of pre-designed templates that can be tailored to meet specific business needs, saving time on form creation.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={features_conditional} alt='img'/>
                                    </div>
                                    <h3 className="text" >Conditional Logic</h3>
                                    <p className="text">Set up rules within your forms to show or hide fields, send notifications, or trigger actions based on user responses, enhancing form interactivity and efficiency.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={Multi_StepForms} alt='img'/>
                                    </div>
                                    <h3 className="text" > Multi-Step Forms</h3>
                                    <p className="text"> Break down lengthy forms into multiple steps or sections, improving user experience and completion rates.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={Real_TimeDataAnalytics} alt='img'/>
                                    </div>
                                    <h3 className="text" >Real-Time Data Analytics</h3>
                                    <p className="text">Monitor and analyze form submissions in real-time, with the ability to generate reports and visualize data for deeper insights.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={AdvancedFormFieldOptions} alt='img'/>
                                    </div>
                                    <h3 className="text" >Advanced Form Field Options</h3>
                                    <p className="text">Include a variety of field types, such as text, dropdowns, checkboxes, file uploads, signatures, and more, to capture diverse data inputs.</p>
                                </div>
                            </div>
                       

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={AutomatedEmailNotifications} alt='img'/>
                                    </div>
                                    <h3 className="text" >Automated Email Notifications</h3>
                                    <p className="text">Set up automatic email notifications to be sent upon form submission, ensuring that you and your team are instantly informed.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={ResponsiveMobile} alt='img'/>
                                    </div>
                                    <h3 className="text" >Responsive and Mobile-Friendly Design</h3>
                                    <p className="text"> Ensure that forms are accessible and functional on any device, providing a seamless user experience across desktops, tablets, and smartphones.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={FormEmbedding_and_Sharing} alt='img'/>
                                    </div>
                                    <h3 className="text" >Form Embedding and Sharing</h3>
                                    <p className="text">Easily embed forms on websites, blogs, or share them via direct links, email, or social media to reach your audience wherever they are.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={SecureDataHandling} alt='img'/>
                                    </div>
                                    <h3 className="text" >Secure Data Handling</h3>
                                    <p className="text">Benefit from robust data encryption and compliance with data protection standards, ensuring the security and privacy of all form submissions.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={FileUploadCapability} alt='img'/>
                                    </div>
                                    <h3 className="text" > File Upload Capability</h3>
                                    <p className="text">Allow users to upload documents, images, or other files directly through your forms, simplifying the process of collecting additional information.</p>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={CustomizableThemes} alt='img'/>
                                    </div>
                                    <h3 className="text" >Customizable Themes and Branding</h3>
                                    <p className="text">Match forms with your company’s branding by customizing colors, fonts, and logos, creating a consistent look and feel.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={APIsforThirdpartyintegration} alt='img'/>
                                    </div>
                                    <h3 className="text" >APIs for Third party integration</h3>
                                    <p className="text">Connect Giga-Forms with  third-party applications to streamline workflows and data management.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={UserAccessControl} alt='img'/>
                                    </div>
                                    <h3 className="text" >User Access Control</h3>
                                    <p className="text">Manage permissions and access levels for team members, ensuring that only authorized users can edit forms or view submissions.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={FormAnalyticsDashboard} alt='img'/>
                                    </div>
                                    <h3 className="text" >Form Analytics Dashboard</h3>
                                    <p className="text">Access a comprehensive dashboard to track form performance, including metrics like submission rates, user behavior, and drop-off points.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <img src={DataExportOptions} alt='img'/>
                                    </div>
                                    <h3 className="text" > Data Export Options</h3>
                                    <p className="text">Export form data in various formats (CSV, Excel, PDF) for easy integration with other systems or for reporting purposes.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}
