import React, { Component } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { connect } from 'react-redux';
import profileImage from '../../../Images/demoProfile.png';
import { setNotification } from '../../common/action'
import Breadcrumbs from '../../common/components/Breadcrumbs';
import { getUserProfile, updatePassword, updateProfile } from '../action';
import NameInitialPic from './NameInitialPic';

class Profile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            edit: false,
            name: "",
            email: "",
            phone: "",
            profile: ""
        }
    }
    editProfile = () => {
        var body = {
            "password": this.props.profileData?.password,
            "last_login": this.props.profileData?.last_login,
            "email": this.state.email,
            "name": this.state.name,
            "phone": this.state.phone,
            "profile": this.state.profile,
            "is_staff": this.props.profileData.is_staff,
            "is_active": this.props.profileData.is_active,
            "is_superuser": this.props.profileData.is_superuser,
            "groups": this.props.profileData?.groups,
            "user_permissions": this.props.profileData?.user_permissions
        }
        this.props.updateProfile(this.props.token.access, body)
        this.setState({ edit: false })
    }

    componentDidMount() {
        this.props.getUserProfile(this.props.token.access)
    }

    handleUpdateAction(e) {
        e.preventDefault();
        var body = {
            "old_password": this.state.oldPassword,
            "new_password": this.state.newPassword
        }
        if (this.state.newPassword === this.state.confirmPassword) {
            this.props.updatePassword(this.props.token.access, body);
            this.setState({
                oldPassword: "",
                newPassword: "",
                confirmPassword: ""
            })
        }
        else {
            this.props.setNotification(true, "Password Doesn't Match")
        }
    }
    render() {
        console.log("inprofile",this.props.profileData)
        return (
            <div>
                <h4 className='m-4'>User Profile</h4>
                <div className='m-4'>
                    <Breadcrumbs name={this.props} />
                </div>
                <Row className='ms-4'>
                    <Col md="6">
                        <Card className='pb-2'>
                            <Card.Header className='d-flex justify-content-center'>
                                {
                                    this.state.edit ?
                                        <form action="upload.php" method="post" enctype="multipart/form-data">
                                            <label for="fileToUpload">
                                                <div className="profile-pic" style={{ backgroundImage: `url(${this.state.profile ? URL.createObjectURL(this.state.profile) : profileImage})` }}>
                                                    <span className="fa fa-edit  fa-lg"></span>
                                                </div>
                                            </label>
                                            <input type="File" name="fileToUpload" id="fileToUpload" onChange={event => { this.setState({ profile: event.target.files[0] }) }} />
                                        </form>
                                        :
                                        <div style={{ height: 130, width: 130 }}>
                                            {
                                                this.props.profileData.profile === undefined || this.props.profileData.profile === null ?
                                                    <div style={{ height: 100, width: 100, borderRadius: "50%" }}>
                                                        <NameInitialPic fontSize={50} name={this.props.profileData.name} />
                                                    </div> : <img src={this.props.profileData.profile} className="rounded-circle" style={{ height: 130, width: 130, borderRadius: "50%" }} alt='user_picture' />
                                            }
                                        </div>
                                }
                            </Card.Header>
                            <Card.Body className='p-4'>
                                <div className='ps-3 pe-3 card-row' >
                                    <span>Name</span>
                                    {
                                        this.state.edit ?
                                            <Form.Control size='sm' style={{ width: "50%" }} autoFocus type="text" placeholder="Enter Your Name" required
                                                onChange={event => { this.setState({ name: event.target.value }); }}
                                                value={this.state.name} /> :
                                            <span>{this.props.profileData.name}</span>
                                    }

                                </div>
                                <div className='divider mt-2'></div>
                                <div className='ps-3 pe-3 mt-3 card-row' >
                                    <span>Email</span>
                                    {
                                        this.state.edit ?
                                            <Form.Control size='sm' style={{ width: "50%" }} type="email" placeholder="Enter Your Email Id" required
                                                onChange={event => { this.setState({ email: event.target.value }); }}
                                                value={this.state.email} /> :
                                            <span>{this.props.profileData.email}</span>
                                    }
                                </div>
                                <div className='divider mt-2'></div>
                                <div className='ps-3 pe-3 mt-3 card-row'>
                                    <span>Phone No</span>{
                                        this.state.edit ?
                                            <Form.Control size='sm' style={{ width: "50%" }} type="number" placeholder="Enter Your Phone No" required
                                                onChange={event => { this.setState({ phone: event.target.value }); }}
                                                value={this.state.phone} /> :
                                            <span>{this.props.profileData.phone}</span>
                                    }
                                </div>
                                <div className='divider mt-2'></div>
                                <div className="mt-3 d-flex justify-content-end">
                                    {
                                        this.state.edit ?
                                            <Button variant="success" onClick={this.editProfile} size='sm' >UPDATE</Button>
                                            :
                                            <Button variant="warning" onClick={() => this.setState({ edit: true, name: this.props.profileData.name, email: this.props.profileData.email, phone: this.props.profileData.phone })} size='sm' >EDIT</Button>
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card className='p-4 pb-3'>
                            <Card.Header>
                                Change Password
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={(e) => { this.handleUpdateAction(e) }} className='font-medium'>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Old Password <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control size='sm' type="password" placeholder="Enter Old Password" required
                                            onChange={event => { this.setState({ oldPassword: event.target.value }); }}
                                            value={this.state.oldPassword} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>New Password <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control size='sm' type="password" placeholder="Enter New Password" required
                                            onChange={event => { this.setState({ newPassword: event.target.value }); }}
                                            value={this.state.newPassword} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Confirm Password <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control size='sm' type="password" placeholder="Enter Confirm Password" required
                                            onChange={event => { this.setState({ confirmPassword: event.target.value }); }}
                                            value={this.state.confirmPassword} />
                                    </Form.Group>
                                    <Form.Group className="mt-3 d-flex justify-content-end" >
                                        <Button type='submit' variant="warning" size='sm' >UPDATE</Button>
                                    </Form.Group>
                                </Form>
                            </Card.Body>

                        </Card>
                    </Col>
                </Row>
            </div >
        )
    }
}



const mapStateToProps = state => {
    return {
        token: state.usersReducer.token,
        profileData: state.usersReducer.profileData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePassword: (token, body) => dispatch(updatePassword(token, body)),
        getUserProfile: (token) => dispatch(getUserProfile(token)),
        setNotification: (status, msg, color) => dispatch(setNotification(status, msg, color)),
        updateProfile: (token, body) => dispatch(updateProfile(token, body))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);