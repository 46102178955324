import React, { Component } from 'react';
import { Nav, Navbar, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import logo from '../../../Images/final.png';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import { connect } from 'react-redux';
import { fetchPartner } from '../../common/action';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
 class Navbr extends Component {
  state = {
    expanded: false,
    show: false,
    name: '',
    email: '',
    company: '',
    inquiry:'For sales enquiry',
    contactNo: '',
    subject: '',
    message: '',
    designation: '',
    partnerStatus: false,
    country:'',
    products: [], // Add this line
    interestedProduct: '' // Add this line
  };
  componentDidMount() {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "token 1f229780140f7fb:acf7716d7fee107");
    myHeaders.append("Cookie", "full_name=Guest; sid=Guest; system_user=no; user_id=Guest; user_image=");
  
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      // Remove the body property
      redirect: "follow"
    };
  
    fetch("https://erp.epsumlabs.in/api/resource/Product?fields=[\"product_name\",\"product_type\",\"name\"]", requestOptions)
      .then((response) => response.json()) // Convert response to JSON
      .then((result) => {
        
        this.setState({ products: result.data }); // Assuming the API returns an array of products in `data`
      })
      .catch((error) => console.error(error));
  }
  

  async handledemoSubmit(e) {
    e.preventDefault();
    let requestBody = {
      data: [
        {
          value:this.state.name,
          field: 226,
        },
        {
          value: this.state.email,
          field: 227,
        },
        {
          value: this.state.company,
          field: 228,
        },
        {
          value: this.state.contactNo,
          field: 229,
        },

        {
          value: this.state.designation,
          field: 230,
        },
        {
          value: this.state.message,
          field: 231,
        },
        {
          value: "Gigaforms",
          field: 232,
        }
      ],
    };
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "token 1f229780140f7fb:acf7716d7fee107");
    myHeaders.append("Content-Type", "application/json");
    const requestData = JSON.stringify({
      partner_name:this.state.name,
      email:this.state.email,
      phone:"+"+this.state.contactNo,
      partner_company:this.state.company,
      country:this.state.country,
      enquiry_type:this.state.inquiry,
      interested_product:this.state.interestedProduct,
      message:this.state.message
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: requestData,
      redirect: "follow"
    };
    try {
    if (this.state.name !== "" && this.state.message !== "" && this.state.email !== "" && this.state.contactNo !== "" && this.state.inquiry !== "" && this.state.interestedProduct !== "") {
      // this.props.storeData("f849f3f6-b439-4b04-b9ac-91f756f6b6ac", requestBody);
      const response = await fetch("https://erp.epsumlabs.in/api/resource/Product Sales Partner", requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.text();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your Response was Recorded Successfully",
        confirmButtonColor: "#11134c",
        confirmButtonText: "Ok",
      });
      this.setState({
        demoname: "",
        demoemail: "",
        demomsg: "",
        demonumber: "",
        show:false
      });
    
    } else {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please fill all the fields",
        confirmButtonColor: "#11134c",
        confirmButtonText: "Ok",
        allowOutsideClick: false,

      })
    }}
    catch (error) {
      console.error("Error:", error.message || error);
    }
  }
  render() {
    console.log("value",this.state.name,this.state.company,this.state.designation,this.state.message,this.state.contactNo,this.state.email)
    return (
      <div style={{position:"sticky",top:0,zIndex:"9"}}>
        <Navbar collapseOnSelect expand="lg" variant="danger" className='navbar sticky-top'>
          <Container className='navtext'>
            <div className='navimg'>
              <Navbar.Brand href="/"><img src={logo} style={{ height: "60px", width: "200px" }} alt='logo img'></img></Navbar.Brand></div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" style={{ marginLeft: "30%" }}>
              <Nav className="me-auto navcol">
                <Button onClick={() => { this.setState({ show: true, partnerStatus: true }) }} style={{ color: "white", backgroundColor: "#fd6517", border: "none", borderRadius: "10px", width: "120px", height: "35px",  marginTop: "2px" }}><b style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                 
                }}>Be A Partner</b></Button>
                <Link to="/Feature" style={{ color: "black", paddingRight: "20px",padding:"0.5rem 20px 0.5rem 0.5rem",textDecoration:"none" }}>Features</Link>
                <Link to="/Pricing" style={{ color: "black", paddingRight: "20px",padding:"0.5rem 20px 0.5rem 0.5rem",textDecoration:"none" }}>Plans & Pricing</Link>
                <div className="dropdown" style={{ paddingTop: "9px", paddingRight: "10px",paddingLeft:"8px" }}>
                  <span>Resources<i className="fa fa-caret-down" aria-hidden="true" style={{marginLeft:"6px"}}></i></span>
                  <div className="dropdown-content">
                    <a href='/faq'>FAQ</a>
                    <a href='/userguide'>User Guide</a>
                    <a href='/video'>Videos</a>
                  </div>
                </div>
                <Link to='/ContactUs' style={{ color: "black", padding:"0.5rem 20px 0.5rem 0.5rem",textDecoration:"none"  }}>Contact Us</Link>
              </Nav>
              <Nav>
                <Button href='/register' style={{ color: "white", backgroundColor: "#fd6517", border: "none", borderRadius: "10px", width: "100px" }}>Signup</Button>{' '}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Modal show={this.state.show} onHide={() => { this.setState({ show: false }) }}>
                <Modal.Header closeButton style={{ backgroundColor: "#fd6517" }}>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                  aspectRatio: " 6/4",
                 
                }}>
                  <Form onSubmit={this.handleFormSubmit}>
                    <Form.Group controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter your name"  required   
                      onChange={(e)=>{
                        this.setState({name:e.target.value})
                      }}
                      />
                    </Form.Group>

                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter your email"
                      onChange={(e)=>{
                        this.setState({email:e.target.value})
                      }}
                      />
                    </Form.Group>

                  
                    <Form.Label>Contact Number</Form.Label>
                    <PhoneInput
                                                containerStyle={{ width: "190px",marginTop: "30px" }}
                                                inputStyle={{ width: "470px", height: "35px",marginTop:"-32px" }}


                                                country={"in"}
                                                enableSearch={true}
                                                value={this.state.contactNo}
                                                onChange={event => { this.setState({ contactNo: event }); }} style={{color: "black", marginTop: "30px"}}
                                            />
                    {/* <Form.Group controlId="designation">
                      <Form.Label>Designation</Form.Label>
                      <Form.Control type="text" placeholder="Enter your designation" value={this.state.designation} required name="designation" onChange={(e)=>{
                          this.setState({designation:e.target.value})
                      }} />
                    </Form.Group> */}

                  
<Form.Group controlId="company">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter your company name" value={this.state.company} required name="company" onChange={(e)=>{
                        this.setState({company:e.target.value})
                      }} />
                    </Form.Group>
                    <Form.Group controlId="country">
                      <Form.Label>Country</Form.Label>
                      <Form.Control type="text" placeholder="Enter your country name" value={this.state.country} required name="country" onChange={(e)=>{
                        this.setState({country:e.target.value})
                      }} />
                    </Form.Group>
                    <Form.Group controlId="inquiry">
  <Form.Label>enquiry</Form.Label>
  <Form.Control
    as="select"
    value={this.state.inquiry}
    required
    name="inquiry"
    onChange={(e) => {
      this.setState({ inquiry: e.target.value });
    }}
  >
    
    <option value="For sales enquiry" selected>For sales enquiry</option>
    <option value="For partner's enquiry">For partnership enquiry</option>
    
    {/* Add more options as needed */}
  </Form.Control>
</Form.Group>

<Form.Group controlId="interestedProduct">
  <Form.Label>Interested Product</Form.Label>
  <Form.Control
    as="select"
    value={this.state.interestedProduct}
    required
    name="interestedProduct"
    onChange={(e) => this.setState({ interestedProduct: e.target.value })}
  >
    <option value="">Select a product</option>
    {this.state.products.map((product) => (
      <option key={product.name} value={product.name}>
        {product.product_name}
      </option>
    ))}
  </Form.Control>
</Form.Group>

                    <Form.Group controlId="message">
                      <Form.Label>Message</Form.Label>
                      <Form.Control as="textarea" rows={3} placeholder="Enter your message" value={this.state.message} required name="message" onChange={(e)=>{
                        this.setState({message:e.target.value})
                      }} />
                    </Form.Group>



                    <Button variant="primary" type="submit" style={{ marginTop: "5px", backgroundColor: "#fd6517", border: "none",float:"right" }}
                    onClick={(e) => {
                      this.handledemoSubmit(e);
                    }}>
                      Submit
                    </Button>
                  </Form>
                </Modal.Body>
               
              </Modal>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    storeData: (token, requestBody) =>
      dispatch(fetchPartner(token, requestBody)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbr);